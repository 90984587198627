<template>
  <ThemeProvider>
    <!-- Maintenance Banner -->
    <MaintenanceBanner />
    <TopHeader />
    <main :class="{
        'green-staging': isStaging && isGreen,
        'blue-staging': isStaging && isBlue
      }">
      <div class="content">
        <slot>
          No content passed to show.
        </slot>
      </div>
    </main>
  </ThemeProvider>
  <Notifications to=".theme-provider"/>
</template>

<script setup lang="ts">
import TopHeader from '@/components/TopHeader';
import TransitionCustom from '@/components/TransitionCustom.vue';
import ThemeProvider from '@/components/ThemeProvider';
import Notifications from "@/components/Notifications/Notifications.vue";
import {ref, onMounted} from "vue"
import useDataSourceStore from '@/stores/dataSource';
import MaintenanceBanner from './MaintenanceBanner.vue';
const { activeDataSourceBaseUrl } = useDataSourceStore();

const isStaging = ref(false);
const isGreen = ref(false);
const isBlue = ref(false);
onMounted(()=>{
  fetch(`${activeDataSourceBaseUrl}/environment`)
  .then((response) => response.json())
  .then((data) => {
    isStaging.value = data?.env==="STAGE";
    isGreen.value = data?.server==="GREEN";
    isBlue.value = data?.server==="BLUE";
  })
})
</script>

<style lang="scss" scoped>
main {
  display: flex;
  justify-content: center;
}

.green-staging {
  background-color: green;
}

.blue-staging {
  background-color: blue;
}

.content {
  background-color: white;
  width: 100%;
  padding: 0 1rem;
  margin-bottom: 13rem;
  @include theme-old {
    /* width: 100vw; */
    max-width: 1200px;
    @include screen-width-up("1232px") {
      padding: initial;
    }
  }
  display: flex;
  flex-direction: column;
  @include x-large-devices-down {
    justify-content: center;
    padding: 1rem;
  }
  @include medium-devices-down {
    margin-top: 7em;
  }
}
</style>
