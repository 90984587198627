<template><content-with-loading-skeleton
    :isLoading="isPlayerLoading"
    loadingMessage="Loading Player Data..."
    :isWithMessage="true"
  >
    <template #loaded>
        <div>
        <h1 class="title">
            Player Editor
            <img src="../../components/icons/OldThemeIconRed/images/refresh-button.png" @click="loadPlayer(playerId)">
        </h1>
    </div>
    <div class="separetor form-fields" v-if="selectedPlayer">
        <div class="md:basis-1/2">
            <div class="flex items-center">
                <h2 class="details-hdr">
                Player Details</h2>
                <ReassignPlayerForm :playerId="selectedPlayer.id" v-if="user.isAccountAdmin()" />
            </div>
            
            <StatusBar :errorMessage="errorMessage" :isLoading="/* true ||  */isLoading" loadingMessage="Saving..."
                :isSuccess="isSuccess" successMessage="Saved!" />
            <div class=" py-4 field-content">
                <div class="d-flex ">
                    <label for="name" class="basis-1/2 text-sm font-bold">Name &#9733;</label>
                    <TextField v-model="values.name" class="basis-1/2" width="100%" />
                </div>
                <div class="d-flex">
                    <label for="Description" class="basis-1/2 text-sm font-bold">Description</label>
                    <TextField width="100%" v-model="values.description" class="basis-1/2" />
                </div>
                <div class="d-flex ">
                    <label class="basis-1/2 text-sm font-bold">Device Model</label>
                    <label class="basis-1/2 text-sm">
                        {{ selectedPlayer?.deviceType?.model }}
                    </label>
                </div>
                <div class="d-flex ">
                    <label class="basis-1/2 text-sm font-bold">Capabilities</label>
                    <label class="basis-1/2 text-sm">
                        <span v-for="mime of selectedPlayer?.deviceType?.mimeTypes"> {{ mime.name }} {{ ' ' }}</span>

                    </label>
                </div>
                <div class="d-flex ">
                    <label class="basis-1/2 text-sm font-bold">Orientation</label>
                    <label class="basis-1/2 text-sm">
                        <OrientationEditor @save="changeOrientation($event)" :orientation="selectedPlayer.orientation"
                            :title="selectedPlayer.name"> </OrientationEditor>
                    </label>
                </div>
                <div class="d-flex ">
                    <label class="basis-1/2 text-sm font-bold">Last Known IP</label>
                    <label class="basis-1/2 text-sm">
                        {{ selectedPlayer?.ipAddress }}
                    </label>
                </div>
                <div class="d-flex ">
                    <label class="basis-1/2 text-sm font-bold">IP Last Updated</label>
                    <label class="basis-1/2 text-sm">
                        <TimeFormatDateOnly :epochTime="selectedPlayer?.dateIpLastUpdated"> </TimeFormatDateOnly>

                    </label>
                </div>
                <div class="d-flex ">
                    <label class="basis-1/2 text-sm font-bold">Date Registered</label>
                    <label class="basis-1/2 text-sm">
                        <TimeFormatDateOnly :epochTime="selectedPlayer?.dateRegistered"> </TimeFormatDateOnly>
                    </label>
                </div>
                <div class="d-flex ">
                    <label class="basis-1/2 text-sm font-bold">Player Version</label>
                    <label class="basis-1/2 text-sm">
                        {{ selectedPlayer?.playerVersion }}
                    </label>
                </div>
                <div class="d-flex ">
                    <label class="basis-1/2 text-sm font-bold">Player Version Updated</label>
                    <label class="basis-1/2 text-sm">
                        <!-- {{ new Date(selectedPlayer?.dateVersionUpdated).toLocaleDateString() }} -->
                        <TimeFormatDateOnly :epochTime="selectedPlayer?.dateVersionUpdated"> </TimeFormatDateOnly>
                    </label>
                </div>
                <div class="d-flex ">
                    <div class="basis-1/2 text-sm font-bold">
                        <SyncSplitConfig v-model="values.syncConfig" />
                    </div>
                </div>
            </div>

        </div>
        <div class="md:basis-1/2">
            <h2>
                Location Information
            </h2>
            <div class="py-4 field-content">
                <div class="change-location">
                    <SelectLocationDialog :btnTitle="'Change Location'" @save="changeLocation($event)">
                    </SelectLocationDialog>

                </div>
                <div class="d-flex ">
                    <label class="basis-1/2 text-sm font-bold">Name / Store Number</label>
                    <label class="basis-1/2 text-sm">
                        {{ selectedPlayer?.location?.name }}
                    </label>
                </div>
                <div class="d-flex ">
                    <label class="basis-1/2 text-sm font-bold">Country</label>
                    <label class="basis-1/2 text-sm">
                        {{ selectedPlayer?.location?.country }}

                    </label>
                </div>
                <div class="d-flex ">
                    <label class="basis-1/2 text-sm font-bold">City</label>
                    <label class="basis-1/2 text-sm">
                        {{ selectedPlayer?.location.city }}
                    </label>
                </div>
                <div class="d-flex ">
                    <label class="basis-1/2 text-sm font-bold">State</label>
                    <label class="basis-1/2 text-sm">
                        {{ selectedPlayer?.location.state }}
                    </label>
                </div>
                <div class="d-flex ">
                    <label class="basis-1/2 text-sm font-bold">Zip</label>
                    <label class="basis-1/2 text-sm">
                        {{ selectedPlayer?.location.zip }}
                    </label>
                </div>
                <div class="d-flex ">
                    <label class="basis-1/2 text-sm font-bold">Region</label>
                    <label class="basis-1/2 text-sm">
                        {{ selectedPlayer?.location.region }}
                    </label>
                </div>
                <div class="d-flex ">
                    <label class="basis-1/2 text-sm font-bold">Contact Name </label>
                    <label class="basis-1/2 text-sm">
                        {{ selectedPlayer?.location.contactName }} </label>
                </div>
                <div class="d-flex ">
                    <label class="basis-1/2 text-sm font-bold">Phone Number </label>
                    <label class="basis-1/2 text-sm">
                        {{ selectedPlayer?.location.phoneNumber }} </label>
                </div>
                <div class="d-flex ">
                    <label class="basis-1/2 text-sm font-bold">Hours </label>
                    <label class="basis-1/2 text-sm">
                        {{ selectedPlayer?.location.hours }}</label>
                </div>
                <div class="d-flex ">
                    <label class="basis-1/2 text-sm font-bold">Time Zone
                    </label>
                    <label class="basis-1/2 text-sm">
                        {{ selectedPlayer?.location.timezone }} </label>
                </div>
            </div>
        </div>

    </div>
    <div class="separetor form-fields mt-3" v-if="selectedPlayer">
        <div class="md:basis-1/2">
            <div class="d-flex align-items-center">
                <h2 class="mr-2">Tags</h2>
                <AddTag :player="selectedPlayer" @save="addTag($event)"> </AddTag>
            </div>
            <div>
                <div class="overflow-x-auto">
                    <table>
                        <thead>
                            <tr class="table-separetor">
                                <th>Category</th>
                                <th>Value</th>
                                <th>Data Type</th>
                                <th>Last Modified Date</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(metadata, index) of selectedPlayer.metaDatas">
                                <tr v-if="!metadata.isDeleted">
                                    <td> {{ metadata.name }}</td>
                                    <td> {{ metadata.value }}</td>
                                    <td> {{ metadata.datatype }}</td>
                                    <td>
                                        <TimeFormatDateOnly :epochTime="metadata.createdDate"> </TimeFormatDateOnly>

                                    </td>
                                    <td>
                                        <DeleteTag @save="deleteTag(metadata.id, index)"> </DeleteTag>
                                    </td>
                                </tr>
                            </template>

                        </tbody>
                    </table>
                </div>
            </div>

            <div class="d-flex w-full">
                <div class="mt-4 w-full">
                    <div class="d-flex justify-between  align-items-ceneter">
                        <div class="d-flex align-items-ceneter">
                            <h2 class="mr-2">Assigned Campaigns</h2>
                            <AddCampaign @save="addCampaign($event)" :playerTitle="selectedPlayer.name">
                            </AddCampaign>
                        </div>
                        <PlayerPreview :playerId="selectedPlayer.id" :playerName="selectedPlayer.name"> </PlayerPreview>
                    </div>

                    <div class="overflow-x-auto">
                        <div class="md:basis-1/2">
                            <table>
                                <thead>
                                    <tr class="table-separetor">
                                        <th>Name</th>
                                        <th>Organization</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(campaign, index) of selectedPlayer.campaigns">
                                        <tr>
                                            <td>
                                                <RouterLink
                                                    :to="{
                                                        name: 'campaign-editor',
                                                        params: {id: campaign.id}
                                                    }" class="campaign-link">
                                                        {{ campaign.name }}
                                                </RouterLink>
                                            </td>
                                            <td> {{ campaign.groupName }}</td>
                                            <td>
                                                <TimeFormatDateOnly :epochTime="campaign.startDate"> </TimeFormatDateOnly>
                                            </td>
                                            <td>
                                                <TimeFormatDateOnly :epochTime="campaign.endDate"> </TimeFormatDateOnly>

                                            </td>
                                            <td>
                                                <OldThemeIcon name='delete' @click.stop="deleteCampaign(index)"
                                                    :class="['button']" />
                                            </td>
                                        </tr>
                                    </template>

                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>
            </div>
        </div>
        <div class="md:basis-1/2 mb-3" v-if="user.isAnalytics()">
            <UptimeSearch :lastConnectTime="selectedPlayer.lastConnectTime" :playerId="playerId"></UptimeSearch>
        </div>
    </div>




    <div class="edit-footer editor-btn content">

        <div class="form-buttons">
            <ButtonCustomized label="Exit" v-if="!hasChanges" @click="onExit" secondary />
            <BeforeExitDialogue v-if="hasChanges" @save="submitAndExit" @exit="onExit"> </BeforeExitDialogue>
            <ButtonCustomized label="Save" @click="onSubmit" :disabled="!hasChanges" />
            <Saving ref="savingDialog"></Saving>

        </div>
    </div>
    </template>
</content-with-loading-skeleton>
</template>
<script  lang="ts" setup>
import usePlayerEditorForm from './PlayerEditorViewHelper';
import { onBeforeMount, onMounted, onUnmounted, ref, watchEffect } from 'vue'
import { onBeforeRouteLeave, useRoute } from 'vue-router'
import useNotifications from "@/components/Notifications/composables/notifications";
import useUser from '@/composables/user';
import ButtonCustomized from '@/components/form-fields/ButtonCustomized.vue';
import Saving from '@/components/Dialog/SavingDialog/Saving.vue'
import BeforeExitDialogue from '@/components/Dialog/BeforeExitDialog/BeforeExitDialogue.vue'
import TextField from "@/components/form-fields/TextField.vue";
import SelectLocationDialog from '../PlayersListView/CreatePlayerForm/SelectLocationDialog.vue'
import TimeFormatDateOnly from '@/components/ListingPages/ItemsLister/ItemsList/FieldsRenderers/TimeFormatDateOnly.vue'
import router from '@/router';
import AddTag from './Tags/AddTag.vue'
import DeleteTag from './Tags/DeleteTag.vue'
import AddCampaign from './AddCampaign/AddCampaignDialog.vue';
import OldThemeIcon from '@/components/icons/OldThemeIcon.vue';
import StatusBar from "@/components/form-fields/StatusBar/StatusBar.vue";
import PlayerPreview from './PlayerPreview/PlayerPreview.vue';
import OrientationEditor from './OrientationEditor/OrientationEditor.vue';
import UptimeSearch from './UptimeSearch/UptimeSearch.vue';
import ReassignPlayerForm from './ReassignPlayer/ReassignPlayerForm.vue'
import SyncSplitConfig from './SyncSplitConfig/SyncSplitConfig.vue';

const route = useRoute()
const notification = useNotifications();
const savingDialog = ref()
const user = useUser();

const { loadPlayer, playerId,
    isLoading,
    isPlayerLoading,
    errorMessage,
    selectedPlayer, deleteTag, values, hasChanges, addTag, submitForm,
    isSuccess, removeBeforeLoad } = usePlayerEditorForm();
onBeforeMount(() => {
    loadPlayer(route.params.id)
})
function changeLocation(newLocation: any) {
    hasChanges.value = true
    selectedPlayer.value.location = newLocation
}
onUnmounted(() => {
    removeBeforeLoad()
})
onBeforeRouteLeave((to, from) => {
    if (hasChanges.value) {
        const answer = window.confirm(
            'Changes you made may not be saved.'
        )
        if (!answer) return false

    }
})


function onExit() {
    hasChanges.value = false 
    router.push({ path: '/players' })
}
async function submitAndExit() {
    savingDialog.value.open()
    await submitForm();
    savingDialog.value.close()
    if (isSuccess.value === true) {
        notification.success('Player updated successfully!');
        removeBeforeLoad();
        router.push({ path: '/players' })
    }
}
async function onSubmit() {
    savingDialog.value.open()
    await submitForm();
    savingDialog.value.close()
    if (isSuccess.value === true) {
        removeBeforeLoad();
        notification.success('Player updated successfully!');
    }
}
function addCampaign(campaignItems: any[]) {
    campaignItems.forEach(item => {
        if (selectedPlayer.value.campaigns.filter((campaign: any) => campaign.id == item.id).length == 0) {
            hasChanges.value = true
            selectedPlayer.value.campaigns.push(item)

        }
    })
}
function deleteCampaign(index: number) {
    hasChanges.value = true
    selectedPlayer.value.campaigns.splice(index, 1)
}
function changeOrientation(value: number) {
    hasChanges.value = true
    selectedPlayer.value.orientation = value
}
// async function saveTag (newTag : object){

// }
</script>
<style  lang="scss" scoped>
.separetor {
    border-top: 1px solid #e6e6e6;
}

.table-separetor {
    border-bottom: 1px solid #e6e6e6;

}

.editor-btn {
    position: fixed;
    bottom: 34px;
    width: fit-content;
    right: 0px;
    max-width: 1200px;
    text-align: right;
    z-index: 5;
    padding-bottom: 10px;
    padding-top: 7px;
}

.status-bar-container {
    margin-bottom: 1rem;
}

.create-slide-form {
    display: flex;
    flex-direction: column;
    max-width: 20rem;
}

.separetor {
    border-top: 1px solid #e6e6e6;
}

.form-fields {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;

    @include medium-devices-down {
        flex-direction: column;
    }
}

.field-content {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

}

.formGroup {
    display: flex;
    align-items: center;

    .select {
        width: 60%;
        margin: 0;
    }

    label {
        display: inline-block;
        width: 120px;
        color: #262626;
        font-weight: bold;
    }
}

.form-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
    margin-top: 1rem;
}

.change-location {
    width: fit-content;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}

th,
td {
    text-align: left;
    padding: 8px;
}

.campaign-link:hover {
    text-decoration: underline;
    color: #93A5AE;
}
.details-hdr{
    padding-right: 0.5rem;
}
.campaign-link {
    color: #25294A;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
}</style>