<template>
      <Dialog :expanderClass="'d-inline'" ref="reassign">
      <template #expander="{ openModal }">
        <a @click="openModal"  class="reassign-link" > 
REASSIGN PLAYER </a>
  
      </template>
      <template #dialog.title>
        Reassign
      </template>
      <template #dialog.content="{ closeModal, closeModalAfter }">
  
  
        <!-- TODO (cleaner code): think about removing redundancy between "DeleteItemConfirmationForm" & other forms (e.g. CreateSlideForm) [DSP-N26Dui5A] -->
        <div>

          <div class="create-slide-form">
      <div class="status-bar-container">
        <StatusBar
          :errorMessage="errorMessage"
          :isLoading="/* true ||  */isLoading"
          loadingMessage="Saving..."
          :isSuccess="isSuccess"
          successMessage="Saved!"
        />
      </div>
      <p class="note">Reassigning a player allows administrators to point a player record to new hardware in the advent that a replacement is required.</p>
       <p class="note"> To proceed, enter the registration code shown on the new device in the field below. The code is 6 digits consisting of letters and numbers. It is not case sentistive.</p>
      <div class="form-fields">
        <!-- TODO: think about adding below-field error message & error state indication (e.g. red border) [DSP-N26CxI4P] -->
        <div class="formGroup">
          <label for="name">Registration Code	 &#9733;</label>
          <TextField
            v-model="values.code"
            width="100%"
          />
        </div>
        <!-- TODO: to re-enable SyncSplitConfig and finalize its implementation with Reassign player -->
        <!-- <SyncSplitConfig /> -->
      </div>
      <div class="form-buttons">
        <ButtonCustomized label="Cancel" @click="close()" :secondary="true" />
        <ConfirmReassign @save="onSubmit($event)" > </ConfirmReassign>

      </div>
    </div>
        </div>
  
      </template>
    </Dialog>

  
  </template>
  
  <script lang="ts" setup>
  import TextField from "@/components/form-fields/TextField.vue";
  import useReassignPlayerForm from "./ReassignPlayerFormHelper";
  import ButtonCustomized from "@/components/form-fields/ButtonCustomized.vue";
  import StatusBar from "@/components/form-fields/StatusBar/StatusBar.vue";
  import useNotifications from "@/components/Notifications/composables/notifications";
  import ConfirmReassign from "./ConfirmReassign.vue";
  import Dialog from '@/components/Dialog/Dialog.vue';
  // import SyncSplitConfig from '../SyncSplitConfig/SyncSplitConfig.vue';
  import { ref } from 'vue'
  import usePlayersListHelpers from "@/views/PlayersListView/PlayersLister/playersListHelpers";

  const reassign = ref ();
  const props = defineProps(['playerId'])
  const itemsListHelpers = usePlayersListHelpers()
  const { reloadList } = itemsListHelpers;

  const notification = useNotifications();
  
  const { values, submitForm, isLoading, isSuccess, errorMessage,playerId ,newPlayerId} = useReassignPlayerForm();
  
  async function onSubmit() {
    playerId.value =  props.playerId
    await submitForm();
    if(isSuccess.value === true) {
      notification.success('Player Reassigned successfully!');
      reloadList()
      close () 
    } 
  }
function close  () {
  values.code = null ;
  isSuccess.value = false ; 
  errorMessage.value =''
  reassign.value.closeModal()

}

  </script>
  
  <style lang="scss" scoped>
  
  .status-bar-container {
    margin-bottom: 1rem;
  }
  
  .create-slide-form {
    display: flex;
    flex-direction: column;
    max-width: 20rem;
  }
  .form-fields {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  .formGroup {
    display: flex;
    align-items: center;
    .select {
      width: 60%;
      margin: 0;
    }
    label {
      display: inline-block;
      width: 110px;
      color: #262626;
      font-weight: bold;
    }
  }
  
  .form-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
    margin-top: 1rem;
  }
  .reassign-link {
    cursor:pointer; 
    font-size: 14px;
    font-weight:bold
  }
  .note {
    margin-bottom : 10px
  }
  </style>
  