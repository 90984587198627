<script setup lang="ts">
import SelectField from "@/components/form-fields/SelectField/SelectField.vue";
import FieldsGroup from '@/components/form-fields/FieldsGroup.vue';
import FieldsRow from '@/components/form-fields/FieldsRow.vue';
import NumberField from '@/components/form-fields/NumberField.vue';
import CheckBoxField from "@/components/form-fields/CheckboxField.vue";
import { ref, computed, watch } from "vue";
const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue'])

const hasSyncConfig = ref(Boolean(props.modelValue) || false);

const availableMatrixCols = [1, 2, 3, 4, 5, 6];
const availableMatrixRows = [1, 2, 3, 4, 5, 6];
const availableMatrixPositionCols = computed(() => Array.from({ length: matrixCols.value }, (_, i) => i + 1))
const availableMatrixPositionRows = computed(() => Array.from({ length: matrixRows.value }, (_, i) => i + 1))
const matrixCols = ref(props.modelValue?.matrix.cols || 1)
const matrixRows = ref(props.modelValue?.matrix.rows || 1)
const matrixPositionCol = ref(props.modelValue?.matrix.posCol || 1)
const matrixPositionRow = ref(props.modelValue?.matrix.posRow || 1)
const screens = ref(props.modelValue?.screens || 1)
const groupId = ref(!isNaN(props.modelValue?.groupId) ? props.modelValue?.groupId : 1)

const isScreensFieldDisabled = computed(() => matrixCols.value != 1 || matrixRows.value != 1);
const isPositionFieldDisabled = computed(() => matrixCols.value == 1 && matrixRows.value == 1);
watch([matrixCols, matrixRows], () => {
  // Update position values based on cols or rows change
  if(matrixPositionCol.value > matrixCols.value) {
    matrixPositionCol.value = matrixCols.value
  }
  if(matrixPositionRow.value > matrixRows.value) {
    matrixPositionRow.value = matrixRows.value
  }
  
  // Update screen value based on cols or rows change
  if(!isScreensFieldDisabled.value) return;
  screens.value = matrixCols.value * matrixRows.value;
})

const syncConfigModel = computed(() => {
  if(!hasSyncConfig.value) return null;
  return {
    matrix: {
      cols: matrixCols.value,
      rows: matrixRows.value,
      posCol: matrixPositionCol.value,
      posRow: matrixPositionRow.value,
    },
    screens: screens.value,
    groupId: groupId.value
  }
})

watch([syncConfigModel], () => {
  emit('update:modelValue', syncConfigModel.value)
})
</script>

<template>
  <div class="flex gap-2">
    <CheckBoxField
      name="test"
      v-model="hasSyncConfig"
      required
      class="field"
      label="Sync. / Split Config"
      :suffix-label="false"
      :label-with-margin="false"
    />
  </div>
  <KeepAlive>
    <div class="container" v-if="hasSyncConfig">
      <FieldsGroup label="Matrix">
        <FieldsRow class="sub-fields">
          <SelectField
            :options="availableMatrixCols"
            v-model="matrixCols"
            label="Cols"
          />
          <SelectField
            :options="availableMatrixRows"
            v-model="matrixRows"
            label="Rows"
          />
        </FieldsRow>
      </FieldsGroup>
      <FieldsGroup label="Position">
        <FieldsRow class="sub-fields">
          <SelectField
            label="Cols"
            :options="availableMatrixPositionCols"
            v-model="matrixPositionCol"
            :disabled="isPositionFieldDisabled"
          />
          <SelectField
            label="Rows"
            :options="availableMatrixPositionRows"
            v-model="matrixPositionRow"
            :disabled="isPositionFieldDisabled"
          />
        </FieldsRow>
      </FieldsGroup>
      <FieldsGroup last>
        <NumberField
          label="Screens"
          v-model="screens"
          name="screens"
          :disabled="isScreensFieldDisabled"
          min="1"
        />
        <NumberField
          label="Group Id"
          v-model="groupId"
          name="groupId"
          class="mt-1"
          min="0"
        />
      </FieldsGroup>
    </div>
  </KeepAlive>
</template>

<style lang="scss" scoped>
.container {
  padding: 1rem 1rem 0 1rem;
  border: rgba(65, 68, 92, 0.1) solid 1px;
  border-radius: 1rem;
  margin-left: 1rem;
  margin-top: 1rem;
}

.sub-fields :deep(label) {
  font-size: 12px;
  color: rgba(38, 38, 38, 0.9);
}
</style>