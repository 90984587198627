import useApi from '../api';

interface QueryOptions {
  fieldNames: string[];
  term: string;
}

interface FilterOption {
  name: string;
  selectedOptions: string[];
}

interface SortOption {
  fieldName: string;
  isDescending: boolean;
}

interface FilterOptions {
  limit?: number;
  offset?: number;
  query?: QueryOptions;
  filters?: FilterOption[];
  sorts?: SortOption[];
}

interface AuditRecordResponse {
  total: number;
  records: any[]; 
}

/**
 * Fetch audit records based on filter options.
 * @param {FilterOptions} filterOptions - Options for filtering, sorting, and pagination.
 * @returns {Promise<AuditRecordResponse>} The response data containing audit records.
 */
export async function fetchAuditRecords(filterOptions: FilterOptions = {}): Promise<AuditRecordResponse> {
  const { post } = useApi();

  const defaultOptions: FilterOptions = {
    limit: 25,
    offset: 0,
    query: {
      fieldNames: ["Name", "Owner.Group.Name"],
      term: "",
    },
    filters: [
      { name: "FILTER_TYPE", selectedOptions: ["All"] },
      { name: "FILTER_AUDITING", selectedOptions: ["Pending"] },
      { name: "FILTER_CREATED", selectedOptions: ["All"] },
      { name: "FILTER_MODIFIED", selectedOptions: ["All"] },
    ],
    sorts: [
      { fieldName: "DateLastModified", isDescending: true },
    ],
  };

  const requestOptions: FilterOptions = { ...defaultOptions, ...filterOptions };

  try {
    const { responseBody } = await post('assetsearch', requestOptions, {
      isLogoutOnInvalidAuth: true,
    });
    return responseBody;
  } catch (error) {
    console.error('Error fetching audit records:', error);
    throw error;
  }
}
/**
 * Fetch the count of auditing records based on the audit state.
 * Defaults to "Pending" if no state is provided.
 * @param {string} auditState - The state to filter by (e.g., "Pending", "Approved").
 * @returns {Promise<number>} The count of auditing records.
 */
export async function fetchAuditingRecordCount(auditState: string = "Pending"): Promise<number> {
    const { get } = useApi();

    try {
    const { responseBody } = await get(
        `api/assetSearch/GetAuditingRecordCount`,
        { params: { auditState } }
    );

    if (responseBody.success) {
        return responseBody.count;
    } else {
        console.error('Error fetching auditing record count:', responseBody.message);
        throw new Error(responseBody.message || 'Failed to fetch auditing record count.');
    }
    } catch (error) {
    console.error('Error fetching auditing record count:', error);
    throw error;
    }
}
